<template>
	<v-bottom-navigation fixed :app="currentRouteName !== 'Map'" class="bottomNav primary--text" background-color="accent" dark shift>
		<v-btn to="/" router>
			<span>{{ $t("navBar.map") }}</span>

			<v-icon>mdi-monitor-dashboard</v-icon>
		</v-btn>

		<v-btn to="/fleet" router>
			<span>{{ $t("navBar.fleet") }}</span>

			<v-icon>mdi-golf-cart</v-icon>
		</v-btn>

		<v-btn to="/settings" router>
			<span>{{ $t("navBar.settings") }}</span>

			<v-icon>mdi-cogs</v-icon>
		</v-btn>

		<v-btn to="/admin" router v-if="hasRole('admin')">
			<span>Admin</span>

			<v-icon>mdi-shield-account</v-icon>
		</v-btn>

		<nav-popover :selectedSite="selectedSite" :user="user" :list="false" />
	</v-bottom-navigation>
</template>

<script>
import { mapState } from "vuex";
import NavPopover from "@/components/NavPopover.vue";

export default {
	components: {
		NavPopover,
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		currentRouteName() {
			return this.$route.name;
		},
	},
};
</script>
