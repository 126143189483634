<template>
	<div>
		<v-navigation-drawer v-model="drawer" disable-resize-watcher mini-variant hide-overlay :permanent="$vuetify.breakpoint.smAndUp" app floating dark color="accent" width="230">
			<!-- Menu -->
			<nav-popover :selectedSite="selectedSite" :user="user" />
			<v-list nav class="white--text">
				<!-- Map -->
				<v-tooltip content-class="tooltip-menu" right>
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/" router exact v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-monitor-dashboard</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title> </v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">
						{{ $t("navBar.map") }}
					</span>
				</v-tooltip>

				<!-- Fleet -->
				<v-tooltip content-class="tooltip-menu" right>
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/fleet" router exact v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-golf-cart</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">
						{{ $t("navBar.fleet") }}
					</span>
				</v-tooltip>

				<!-- Rides -->
				<v-tooltip content-class="tooltip-menu" right>
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/rides" router exact v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-qrcode-scan</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">
						{{ $t("navBar.rides") }}
					</span>
				</v-tooltip>

				<!-- Charts -->
				<v-tooltip content-class="tooltip-menu" right v-if="hasRole('user')">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/charts/usage" router v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-chart-bar-stacked</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">
						{{ $t("navBar.charts") }}
					</span>
				</v-tooltip>

				<!-- Site Setttings -->
				<v-tooltip content-class="tooltip-menu" right v-if="hasRole('user')">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/settings" router v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-cogs</v-icon>
							</v-list-item-action>

							<v-list-item-content>
								<v-list-item-title> </v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">
						{{ $t("navBar.settings") }}
					</span>
				</v-tooltip>

				<!--  Admin Panel -->
				<v-tooltip content-class="tooltip-menu" right v-if="hasRole('admin')">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item to="/admin/devices" router v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-shield-account</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold">Admin</span>
				</v-tooltip>
				<!-- Rides -->
				<v-tooltip content-class="tooltip-menu" right v-if="hasRole('user')">
					<template v-slot:activator="{ on, attrs }">
						<v-list-item @click="goToHelp()" v-bind="attrs" v-on="on">
							<v-list-item-action>
								<v-icon>mdi-help-box</v-icon>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title></v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<span class="accent--text font-weight-bold"> Documentation & Help </span>
				</v-tooltip>
			</v-list>
		</v-navigation-drawer>
		<support-modal ref="supportModal" />
	</div>
</template>

<script>
import { mapState } from "vuex";
import NavPopover from "@/components/NavPopover.vue";
import SupportModal from "./SupportModal.vue";

export default {
	components: {
		NavPopover,
		SupportModal,
	},
	data() {
		return {
			drawer: true,
		};
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
			selectedSite: (state) => state.sites.selectedSite,
		}),

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/";

				default:
					return "https://help.iotee.eu/en/";
			}
		},
	},
	methods: {
		goToHelp() {
			this.$refs.supportModal.openModal();
		},
	},
};
</script>

<style lang="scss" scoped>
.tooltip-menu {
	background-color: #ffffff !important;
	opacity: 1;
	-webkit-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	-moz-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
}

.tooltip-menu::before {
	left: -10px;
	top: 10px;
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7px 7px 7px;
	border-color: transparent transparent #fff transparent;
	transform: rotate(-90deg);
}
</style>
