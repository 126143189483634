<template>
	<v-dialog v-model="dialogVisible" scrollable :persistent="!hasAccepted" max-width="500px">
		<v-card>
			<v-card-title class="headline">{{ $t("terms.title") }}</v-card-title>
			<v-card-text style="max-height: 100px">
				<v-scroll-y-transition>
					<v-card-text>
						{{ $t("terms.content") }}
					</v-card-text>
				</v-scroll-y-transition>
			</v-card-text>
			<v-card-actions>
				<v-checkbox v-if="!hasAccepted" v-model="accept" required>
					<template v-slot:label>
						<span class="caption">
							{{ $t("terms.acceptLabel") }}
						</span>
					</template>
				</v-checkbox>
				<v-spacer></v-spacer>
				<v-btn v-if="!hasAccepted" color="primary" @click="acceptTerms">{{ $t("terms.accept") }}</v-btn>
				<v-btn v-else color="primary" @click="dialogVisible = false">Close</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			dialogVisible: false,
			accept: false,
			versionNumber: "1.0",
		};
	},
	mounted() {
		if (!this.hasAccepted) {
			this.dialogVisible = true;
		}
	},
	computed: {
		...mapState({
			user: (state) => state.auth.user,
		}),

		hasAccepted() {
			return this.user.acceptedTermsVersion === this.versionNumber;
		},
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},

		acceptTerms() {
			if (this.accept) {
				this.$store.dispatch("updateUser", { acceptedTermsVersion: this.versionNumber, termsAcceptedDate: new Date() });
				this.dialogVisible = false;
			}
		},
	},
};
</script>
