<template>
	<v-menu v-model="menu" :close-on-content-click="false" offset-y>
		<template v-if="list" v-slot:activator="{ on: menu }">
			<v-list nav class="pb-0">
				<v-list-item class="pa-0">
					<v-tooltip content-class="tooltip-menu" right light>
						<template v-slot:activator="{ on: tooltip }">
							<v-list-item-avatar v-on="{ ...tooltip, ...menu }" tile class="mx-auto" size="40" style="cursor: pointer">
								<v-img src="https://storage.googleapis.com/iotee_storage/app_images/LogoSmall.png" eager></v-img>
							</v-list-item-avatar>
						</template>
						<span class="accent--text font-weight-bold">
							{{ selectedSite.name }}
						</span>
					</v-tooltip>
				</v-list-item>
			</v-list>
		</template>

		<template v-slot:activator="{ on, attrs }" v-else>
			<v-chip class="mt-3" color="transparent" v-bind="attrs" v-on="on" depressed>
				<v-icon>mdi-dots-vertical</v-icon>
			</v-chip>
		</template>

		<v-card max-width="400px">
			<v-list>
				<v-list-item>
					<v-list-item-avatar tile size="60">
						<img src="https://storage.googleapis.com/iotee_storage/app_images/Logo.png" eager height="60" />
					</v-list-item-avatar>

					<v-list-item-content>
						<v-list-item-title class="font-weight-bold">
							{{ selectedSite.name }}
						</v-list-item-title>
						<v-list-item-subtitle class="caption">
							{{ user.email }}
						</v-list-item-subtitle>
						<v-list-item-subtitle>
							<v-btn small color="error" text class="font-weight-bold" @click="logout">
								{{ $t("general.logout") }}
							</v-btn>
						</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action>
						<language-select />
						<v-list-item-subtitle class="mt-2">
							<v-btn class="text--accent" depressed x-small @click="showTerms()">Read Terms</v-btn>
						</v-list-item-subtitle>
					</v-list-item-action>
				</v-list-item>
			</v-list>

			<div v-if="hasRole('superadmin') || user.sites.length > 1">
				<v-divider class="primary lighten-4 mx-4 mb-2"></v-divider>
				<v-list class="pt-0">
					<v-list-item style="width: 100%">
						<admin-sites-picker @siteChanged="menu = false" />
					</v-list-item>
				</v-list>
			</div>
		</v-card>
		<terms-and-conditions ref="tcs" />
	</v-menu>
</template>

<script>
import LanguageSelect from "@/components/LanguageSelect";
import AdminSitesPicker from "@/components/AdminSitesPicker";
import TermsAndConditions from "@/components/TermsAndConditions.vue";

export default {
	components: {
		AdminSitesPicker,
		LanguageSelect,
		TermsAndConditions,
	},
	props: {
		selectedSite: {
			required: true,
		},
		user: {
			required: true,
		},
		list: {
			default: true,
		},
	},
	data() {
		return {
			menu: false,
			logo: require("../../public/Logo.png"),
		};
	},
	methods: {
		logout() {
			this.$store.dispatch("logout");
		},

		showTerms() {
			this.$refs.tcs.open();
		},
	},
};
</script>

<style lang="scss" scoped>
.tooltip-menu {
	background-color: #ffffff !important;
	opacity: 1;
	-webkit-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	-moz-box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
	box-shadow: -4px 4px 20px -4px rgba(0, 0, 0, 1);
}

.tooltip-menu::before {
	left: -10px;
	top: 10px;
	position: absolute;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 7px 7px 7px;
	border-color: transparent transparent #fff transparent;
	transform: rotate(-90deg);
}
</style>
