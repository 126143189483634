<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on: menu }">
      <v-btn icon v-on="{ ...menu }">
        <v-avatar size="24">
          <v-img :src="currentLanguageIcon" v-if="currentLanguageIcon" />
          <v-icon v-else>mdi-earth</v-icon>
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="language in languages"
        :key="language.id"
        @click="setLocale(language)"
      >
        <v-list-item-avatar size="24">
          <v-img :src="language.flagSrc"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>{{ language.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      languages: [
        {
          id: "en",
          title: "English",
          flagSrc: "https://flagpedia.net/data/flags/w580/gb.png",
        },
        {
          id: "de",
          title: "Deutsche",
          flagSrc: "https://flagpedia.net/data/flags/w580/de.png",
        },
        {
          id: "da",
          title: "Dansk",
          flagSrc: "https://flagpedia.net/data/flags/w580/dk.png",
        },
      ],
      currentLanguage: null,
    };
  },
  computed: {
    currentLanguageIcon() {
      const locale = this.$i18n.locale;

      if (!locale) {
        return null;
      }

      const lang = this.languages.find((x) => x.id === locale);

      if (lang) {
        return lang.flagSrc;
      }

      return this.languages[0].flagSrc;
    },
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale.id;
      localStorage.setItem("locale", locale.id);
      window.location.reload();
    },
  },
};
</script>
