<template>
	<div class="text-center">
		<v-dialog v-model="dialog" max-width="700px" persistent>
			<v-card class="rounded-xl text-center">
				<v-card-title
					class="title accent white--text mb-4"
					style="border-bottom: 3px solid #406dfa !important"
				>
					{{ $t("helpModal.title") }}
				</v-card-title>
				<v-btn
					@click="dialog = false"
					icon
					small
					style="position: absolute; right: 15px; top: 15px; z-index: 5"
				>
					<v-icon color="white">mdi-close-circle-outline</v-icon>
				</v-btn>

				<v-card-text>
					<v-row>
						<v-col>
							<v-progress-circular
								:size="100"
								:width="3"
								color="primary"
								indeterminate
								v-if="loading"
							>
								<v-img
									class="mx-2"
									src="../../public/Logo.png"
									max-height="40"
									max-width="40"
									contain
								></v-img
							></v-progress-circular>
							<vimeo-player
								v-show="!loading"
								ref="player"
								:video-id="videoID"
								@ready="onReady"
							></vimeo-player>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-text>
					{{ $t("helpModal.subtitle") }}
					<br />
					<v-btn
						color="primary"
						class="font-weight-bold mt-4"
						@click="goToHelp()"
					>
						<v-icon left>mdi-school-outline</v-icon>
						{{ $t("helpModal.button") }}
					</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			loading: true,
		};
	},
	mounted() {
		this.showGettingStartedModal();
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.markVideoAsViewed();
				this.$refs.player.pause();
			}
		},
	},
	computed: {
		videoID() {
			switch (this.$i18n.locale) {
				case "de":
					return "809117162"; // Getting Started - DE Subtitles

				default:
					return "807959876"; // Getting Started
			}
		},

		helpURL() {
			switch (this.$i18n.locale) {
				case "de":
					return "https://help.iotee.eu/de/";

				default:
					return "https://help.iotee.eu/en/";
			}
		},
	},
	methods: {
		openModal() {
			this.dialog = true;
		},

		onReady() {
			console.log("ready");
			this.loading = false;
		},

		showGettingStartedModal() {
			// Check if the user has viewed the video before
			const hideIntroVideo = localStorage.getItem("hideIntroVideo");

			if (!hideIntroVideo) {
				this.dialog = true;
			}
		},

		markVideoAsViewed() {
			// Mark the video as viewed by updating the localStorage flag
			localStorage.setItem("hideIntroVideo", true);
		},

		goToHelp() {
			window.open(this.helpURL, "_blank");
		},
	},
};
</script>
