<template>
	<v-form ref="form" v-model="valid" lazy-validation style="width: 100%">
		<v-autocomplete
			v-model="site"
			:items="sites"
			item-text="name"
			item-value="_id"
			hide-details="auto"
			:menu-props="{ auto: true, offsetY: true }"
			flat
			outlined
			small
			dense
			light
			placeholder="Site..."
			class="font-weight-bold"
			:rules="[rules.required]"
			@change="selectSite()"
		></v-autocomplete>
	</v-form>
</template>

<script>
import { mapState } from "vuex";

export default {
	data() {
		return {
			valid: true,
			rules: {
				required: (value) => !!value || this.$i18n.t("rules.required"),
			},
			siteSelection: null,
		};
	},
	computed: {
		...mapState({
			selectedSite: (state) => state.sites.selectedSite,
			sites: (state) => state.sites.sites,
		}),

		site: {
			get() {
				return this.selectedSite;
			},
			set(newValue) {
				this.siteSelection = newValue;
			},
		},
	},
	methods: {
		selectSite() {
			if (this.$refs.form.validate()) {
				localStorage.setItem("selectedSiteID", this.siteSelection);
				this.$store.commit("setSelectedDevice", null);
				this.$emit("siteChanged");
				window.location.reload();
			}
		},
	},
};
</script>
